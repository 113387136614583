import _extends from "@babel/runtime/helpers/extends";
import 'whatwg-fetch';
import fetchJsonp from 'fetch-jsonp';
import { serializeParams } from '.';
/**
 * this is a private method, export for testing purposes only.
 *
 * @export
 * @param {*} dataAPI
 * @param {*} params
 * @returns
 */

export function buildUrl(dataAPI, params) {
  var paramStr = serializeParams(params);

  if (paramStr) {
    return dataAPI.indexOf('?') > 0 ? dataAPI + "&" + paramStr : dataAPI + "?" + paramStr;
  }

  return dataAPI;
}
/**
 * do Get request
 *
 * @export
 * @param {*} dataAPI
 * @param {*} [params={}]
 * @param {*} [headers={}]
 * @param {*} [otherProps={}]
 * @returns
 */

export function get(dataAPI, params, headers, otherProps) {
  if (params === void 0) {
    params = {};
  }

  if (headers === void 0) {
    headers = {};
  }

  if (otherProps === void 0) {
    otherProps = {};
  }

  var processedHeaders = _extends({
    Accept: 'application/json'
  }, headers);

  var url = buildUrl(dataAPI, params);
  return request(url, 'GET', null, processedHeaders, otherProps);
}
/**
 * do Post request
 *
 * @export
 * @param {*} dataAPI
 * @param {*} [params={}]
 * @param {*} [headers={}]
 * @param {*} [otherProps={}]
 * @returns
 */

export function post(dataAPI, params, headers, otherProps) {
  if (params === void 0) {
    params = {};
  }

  if (headers === void 0) {
    headers = {};
  }

  if (otherProps === void 0) {
    otherProps = {};
  }

  var processedHeaders = _extends({
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  }, headers);

  var body = processedHeaders['Content-Type'].indexOf('application/json') > -1 || Array.isArray(params) ? JSON.stringify(params) : serializeParams(params);
  return request(dataAPI, 'POST', body, processedHeaders, otherProps);
}
/**
 * do request
 *
 * @export
 * @param {*} dataAPI
 * @param {string} [method='GET']
 * @param {*} data
 * @param {*} [headers={}]
 * @param {*} [otherProps={}]
 * @returns
 */

export function request(dataAPI, method, data, headers, otherProps) {
  if (method === void 0) {
    method = 'GET';
  }

  if (headers === void 0) {
    headers = {};
  }

  if (otherProps === void 0) {
    otherProps = {};
  }

  var processedHeaders = headers || {};
  var payload = data;

  if (method === 'PUT' || method === 'DELETE') {
    processedHeaders = _extends({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }, processedHeaders);
    payload = JSON.stringify(payload || {});
  }

  return new Promise(function (resolve, reject) {
    if (otherProps.timeout) {
      setTimeout(function () {
        reject(new Error('timeout'));
      }, otherProps.timeout);
    }

    fetch(dataAPI, _extends({
      method: method,
      credentials: 'include',
      headers: processedHeaders,
      body: payload
    }, otherProps)).then(function (response) {
      switch (response.status) {
        case 200:
        case 201:
        case 202:
          return response.json();

        case 204:
          if (method === 'DELETE') {
            return {
              success: true
            };
          } else {
            return {
              __success: false,
              code: response.status
            };
          }

        case 400:
        case 401:
        case 403:
        case 404:
        case 406:
        case 410:
        case 422:
        case 500:
          return response.json().then(function (res) {
            return {
              __success: false,
              code: response.status,
              data: res
            };
          })["catch"](function () {
            return {
              __success: false,
              code: response.status
            };
          });

        default:
      }

      return null;
    }).then(function (json) {
      if (!json) {
        reject(json);
        return;
      }

      if (json.__success !== false) {
        resolve(json);
      } else {
        // eslint-disable-next-line no-param-reassign
        delete json.__success;
        reject(json);
      }
    })["catch"](function (err) {
      reject(err);
    });
  });
}
/**
 * do jsonp request
 *
 * @export
 * @param {*} dataAPI
 * @param {*} [params={}]
 * @param {*} [otherProps={}]
 * @returns
 */

export function jsonp(dataAPI, params, otherProps) {
  if (params === void 0) {
    params = {};
  }

  if (otherProps === void 0) {
    otherProps = {};
  }

  return new Promise(function (resolve, reject) {
    var processedOtherProps = _extends({
      timeout: 5000
    }, otherProps);

    var url = buildUrl(dataAPI, params);
    fetchJsonp(url, processedOtherProps).then(function (response) {
      response.json();
    }).then(function (json) {
      if (json) {
        resolve(json);
      } else {
        reject();
      }
    })["catch"](function (err) {
      reject(err);
    });
  });
}