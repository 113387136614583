(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@alifd/next")["Icon"], require("@alifd/next")["ConfigProvider"]);
	else if(typeof define === 'function' && define.amd)
		define([["@alifd/next","Icon"], ["@alifd/next","ConfigProvider"]], factory);
	else if(typeof exports === 'object')
		exports["AliLowCodeReactRenderer"] = factory(require("@alifd/next")["Icon"], require("@alifd/next")["ConfigProvider"]);
	else
		root["AliLowCodeReactRenderer"] = factory(root["Next"]["Icon"], root["Next"]["ConfigProvider"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__83__) {
return 