var nativeSelectionEnabled = true;

var preventSelection = function preventSelection(e) {
  if (nativeSelectionEnabled) {
    return null;
  }

  e.preventDefault();
  e.stopPropagation();
  return false;
};

document.addEventListener('selectstart', preventSelection, true);
document.addEventListener('dragstart', preventSelection, true);
export function setNativeSelection(enableFlag) {
  nativeSelectionEnabled = enableFlag;
}