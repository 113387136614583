/**
 * 变量表达式
 *
 * 表达式内通过 this 对象获取上下文
 */

/**
 * 事件函数类型
 * @see https://lowcode-engine.cn/lowcode
 *
 * 保留与原组件属性、生命周期( React / 小程序)一致的输入参数，并给所有事件函数 binding 统一一致的上下文（当前组件所在容器结构的 this 对象）
 */

/**
 * Slot 函数类型
 *
 * 通常用于描述组件的某一个属性为 ReactNode 或 Function return ReactNode 的场景。
 */

/**
 * @deprecated
 *
 * @todo 待文档描述
 */

/**
 * JSON 基本类型
 */

/**
 * 复合类型
 */
export function isJSExpression(data) {
  return data && data.type === 'JSExpression';
}
export function isJSFunction(x) {
  return typeof x === 'object' && x && x.type === 'JSFunction';
}
export function isJSSlot(data) {
  return data && data.type === 'JSSlot';
}
export function isJSBlock(data) {
  return data && data.type === 'JSBlock';
}