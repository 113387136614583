import { isPlainObject } from './is-plain-object';
export function cloneDeep(src) {
  var type = typeof src;
  var data;

  if (src === null || src === undefined) {
    data = src;
  } else if (Array.isArray(src)) {
    data = src.map(function (item) {
      return cloneDeep(item);
    });
  } else if (type === 'object' && isPlainObject(src)) {
    data = {};

    for (var key in src) {
      // eslint-disable-next-line no-prototype-builtins
      if (src.hasOwnProperty(key)) {
        data[key] = cloneDeep(src[key]);
      }
    }
  } else {
    data = src;
  }

  return data;
}