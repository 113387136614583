import _extends from "@babel/runtime/helpers/extends";

function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { isJSBlock, isJSSlot, ActivityType } from '@digiforce/dvd-types';
import { isVariable } from './misc';
import { isPlainObject } from './is-plain-object';

function isJsObject(props) {
  if (typeof props === 'object' && props !== null) {
    return props.type && props.source && props.compiled;
  }
}

function isActionRef(props) {
  return props.type && props.type === 'actionRef';
}
/**
 * 将「乐高版本」协议升级成 JSExpression / JSSlot 等标准协议的结构
 * @param props
 * @returns
 */


export function compatibleLegaoSchema(props) {
  if (!props) {
    return props;
  }

  if (Array.isArray(props)) {
    return props.map(function (k) {
      return compatibleLegaoSchema(k);
    });
  }

  if (!isPlainObject(props)) {
    return props;
  }

  if (isJSBlock(props)) {
    if (props.value.componentName === 'Slot') {
      var _props$value$props, _props$value$props2, _props$value$props3;

      return {
        type: 'JSSlot',
        title: (_props$value$props = props.value.props) === null || _props$value$props === void 0 ? void 0 : _props$value$props.slotTitle,
        name: (_props$value$props2 = props.value.props) === null || _props$value$props2 === void 0 ? void 0 : _props$value$props2.slotName,
        value: compatibleLegaoSchema(props.value.children),
        params: (_props$value$props3 = props.value.props) === null || _props$value$props3 === void 0 ? void 0 : _props$value$props3.slotParams
      };
    } else {
      return props.value;
    }
  }

  if (isVariable(props)) {
    return {
      type: 'JSExpression',
      value: props.variable,
      mock: props.value
    };
  }

  if (isJsObject(props)) {
    return {
      type: 'JSExpression',
      value: props.compiled,
      extType: 'function'
    };
  }

  if (isActionRef(props)) {
    return {
      type: 'JSExpression',
      value: props.id + ".bind(this)"
    };
  }

  var newProps = {};
  Object.keys(props).forEach(function (key) {
    if (/^__slot__/.test(key) && props[key] === true) {
      return;
    } // TODO: 先移除，目前没有业务使用
    // if (key === 'dataSource') {
    //   newProps[key] = props[key];
    //   return;
    // }


    newProps[key] = compatibleLegaoSchema(props[key]);
  });
  return newProps;
}
export function getNodeSchemaById(schema, nodeId) {
  var found;

  if (schema.id === nodeId) {
    return schema;
  }

  var children = schema.children,
      props = schema.props; // 查找 children

  if (Array.isArray(children)) {
    for (var _iterator = _createForOfIteratorHelperLoose(children), _step; !(_step = _iterator()).done;) {
      var child = _step.value;
      found = getNodeSchemaById(child, nodeId);
      if (found) return found;
    }
  }

  if (isPlainObject(props)) {
    // 查找 props，主要是 slot 类型
    found = getNodeSchemaFromPropsById(props, nodeId);
    if (found) return found;
  }
}

function getNodeSchemaFromPropsById(props, nodeId) {
  var found;

  for (var _i = 0, _Object$entries = Object.entries(props); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _Object$entries[_i],
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];

    if (isJSSlot(value)) {
      // value 是数组类型 { type: 'JSSlot', value: NodeSchema[] }
      if (Array.isArray(value.value)) {
        for (var _iterator2 = _createForOfIteratorHelperLoose(value.value), _step2; !(_step2 = _iterator2()).done;) {
          var child = _step2.value;
          found = getNodeSchemaById(child, nodeId);
          if (found) return found;
        }
      } // value 是对象类型 { type: 'JSSlot', value: NodeSchema }


      found = getNodeSchemaById(value.value, nodeId);
      if (found) return found;
    } else if (isPlainObject(value)) {
      found = getNodeSchemaFromPropsById(value, nodeId);
      if (found) return found;
    }
  }
}

export function applyActivities(pivotSchema, activities, options) {
  var schema = _extends({}, pivotSchema);

  if (!Array.isArray(activities)) {
    activities = [activities];
  }

  return activities.reduce(function (accSchema, activity) {
    if (activity.type === ActivityType.MODIFIED) {
      var found = getNodeSchemaById(accSchema, activity.payload.schema.id);
      if (!found) return accSchema;
      Object.assign(found, activity.payload.schema);
    } else if (activity.type === ActivityType.ADDED) {
      var payload = activity.payload;
      var location = payload.location,
          _schema = payload.schema;
      var parent = location.parent;

      var _found = getNodeSchemaById(accSchema, parent.nodeId);

      if (_found) {
        if (Array.isArray(_found.children)) {
          _found.children.splice(parent.index, 0, _schema);
        } else if (!_found.children) {
          _found.children = [_schema];
        } // TODO: 是 JSExpression / DOMText

      }
    } else if (activity.type === ActivityType.DELETED) {
      var _payload = activity.payload;
      var _location = _payload.location;
      var _parent = _location.parent;

      var _found2 = getNodeSchemaById(accSchema, _parent.nodeId);

      if (_found2 && Array.isArray(_found2.children)) {
        _found2.children.splice(_parent.index, 1);
      }
    }

    return accSchema;
  }, schema);
}