/**
 * 嵌套控制函数
 */

/**
 * 嵌套控制
 * 防止错误的节点嵌套，比如 a 嵌套 a, FormField 只能在 Form 容器下，Column 只能在 Table 下等
 */

/**
 * 组件能力配置
 */

/**
 * 可用片段
 *
 * 内容为组件不同状态下的低代码 schema (可以有多个)，用户从组件面板拖入组件到设计器时会向页面 schema 中插入 snippets 中定义的组件低代码 schema
 */

/**
 * 高级特性配置
 */
// thinkof Array

/**
 * Live Text Editing（如果 children 内容是纯文本，支持双击直接编辑）的可配置项目
 */

/**
 * 通用扩展面板支持性配置
 */

/**
 * 编辑体验配置
 */

/**
 * 动作描述
 */

/**
 * @todo 工具条动作
 */
export function isActionContentObject(obj) {
  return obj && typeof obj === 'object';
}
/**
 * 组件 meta 配置
 */