export function createDefer() {
  var r = {};
  var promise = new Promise(function (resolve, reject) {
    r.resolve = resolve;
    r.reject = reject;
  });

  r.promise = function () {
    return promise;
  };

  return r;
}